// From https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

import React, {createContext, useContext, useReducer} from 'react';

/*
const initialState = {
	theme: { primary: 'green' }
};
*/
const initialState = {
  globals: { 
    hideMenu: false,
    showCloseButton: false,
    hideBackground: false,
   	showMobileMenu: false,
    //toggleFullScreen: false,

  },
  downloadLink: null
};


export const StateContext = createContext();
export const StateProvider = ({reducer, children}) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);