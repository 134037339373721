import React from 'react'
import styled from '@emotion/styled'
import { Link } from './LinkWithPrevURL'
import PropTypes from 'prop-types'

import theme from '../theme'

import Menu from './Menu'
import CloseButton from './CloseButton'

import { useStateValue } from '../state'

const Nav = styled.nav`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 48px;
  display: flex;
  align-items: flex-end;
  //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 75%);
  /*background: linear-gradient(
    to bottom,
    hsl(330, 100%, 45.1%) 0%,
    hsl(331, 89.25%, 47.36%) 8.1%,
    hsl(330.53, 79.69%, 48.96%) 15.5%,
    hsl(328.56, 70.89%, 49.96%) 22.5%,
    hsl(324.94, 63.52%, 50.4%) 29%,
    hsl(319.21, 54.99%, 50.3%) 35.3%,
    hsl(310.39, 46.14%, 49.68%) 41.2%,
    hsl(296.53, 39.12%, 49.7%) 47.1%,
    hsl(280.63, 42.91%, 53.43%) 52.9%,
    hsl(265.14, 47.59%, 56.84%) 58.8%,
    hsl(250.13, 52.52%, 59.88%) 64.7%,
    hsl(235.88, 59.2%, 60.91%) 71%,
    hsl(225.81, 68.23%, 57.85%) 77.5%,
    hsl(218.93, 74.97%, 54.21%) 84.5%,
    hsl(213.89, 79.63%, 49.97%) 91.9%,
    hsl(210, 100%, 45.1%) 100%);*/
  text-transform: uppercase;
  font-weight: 100;
  font-size: ${theme.typography.body};
  padding: 0 15px 12px;
  z-index: 10;
  pointer-events: none;

  a {
    color: #fff;
    text-decoration: none;
    pointer-events: auto;
  }

  /*
  &:after {
    content: '';
    position: absolute;
    top: -58px;
    right: 0;
    left: 0;
    height: 58px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 58%);
  }
  */

  @media ${theme.breakpoints.md} {
    a {
      pointer-events: auto;
    }

    //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 75%);
    /*height: 75px;
    background: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.011) 8%,
      hsla(0, 0%, 0%, 0.044) 15.4%,
      hsla(0, 0%, 0%, 0.094) 22.3%,
      hsla(0, 0%, 0%, 0.16) 28.7%,
      hsla(0, 0%, 0%, 0.237) 34.8%,
      hsla(0, 0%, 0%, 0.323) 40.7%,
      hsla(0, 0%, 0%, 0.415) 46.4%,
      hsla(0, 0%, 0%, 0.51) 52.1%,
      hsla(0, 0%, 0%, 0.605) 57.9%,
      hsla(0, 0%, 0%, 0.696) 63.9%,
      hsla(0, 0%, 0%, 0.782) 70.1%,
      hsla(0, 0%, 0%, 0.858) 76.7%,
      hsla(0, 0%, 0%, 0.921) 83.9%,
      hsla(0, 0%, 0%, 0.97) 91.6%,
      hsl(0, 0%, 0%) 100%
    );*/

    &:before {
      content: '';
      pointer-events: none;
      z-index: 5;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 75px;
      background: linear-gradient(
        to bottom,
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0.011) 8%,
        hsla(0, 0%, 0%, 0.044) 15.4%,
        hsla(0, 0%, 0%, 0.094) 22.3%,
        hsla(0, 0%, 0%, 0.16) 28.7%,
        hsla(0, 0%, 0%, 0.237) 34.8%,
        hsla(0, 0%, 0%, 0.323) 40.7%,
        hsla(0, 0%, 0%, 0.415) 46.4%,
        hsla(0, 0%, 0%, 0.51) 52.1%,
        hsla(0, 0%, 0%, 0.605) 57.9%,
        hsla(0, 0%, 0%, 0.696) 63.9%,
        hsla(0, 0%, 0%, 0.782) 70.1%,
        hsla(0, 0%, 0%, 0.858) 76.7%,
        hsla(0, 0%, 0%, 0.921) 83.9%,
        hsla(0, 0%, 0%, 0.97) 91.6%,
        hsl(0, 0%, 0%) 100%
      );
    }

    &:after {
      content: '';
      pointer-events: none;
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        to bottom,
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0.02) 8.1%,
        hsla(0, 0%, 0%, 0.047) 15.4%,
        hsla(0, 0%, 0%, 0.081) 22%,
        hsla(0, 0%, 0%, 0.121) 28.1%,
        hsla(0, 0%, 0%, 0.165) 33.7%,
        hsla(0, 0%, 0%, 0.212) 39.1%,
        hsla(0, 0%, 0%, 0.261) 44.4%,
        hsla(0, 0%, 0%, 0.312) 49.7%,
        hsla(0, 0%, 0%, 0.362) 55.2%,
        hsla(0, 0%, 0%, 0.411) 61%,
        hsla(0, 0%, 0%, 0.458) 67.2%,
        hsla(0, 0%, 0%, 0.501) 74.1%,
        hsla(0, 0%, 0%, 0.54) 81.8%,
        hsla(0, 0%, 0%, 0.573) 90.4%,
        hsla(0, 0%, 0%, 0.6) 100%
      );
      height: 200px;
    }
  }
`

const Inner = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`

const SiteTitle = styled.h1`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  margin: 0;
  font-weight: 100;
  font-size: ${theme.typography.body};

  a {
    position: relative;
    padding: 5px;

    @media ${theme.breakpoints.md} {
      &:after {
        content: '';
        position: absolute;
        right: 5px;
        bottom: 3px;
        left: 5px;
        height: 1px;
        background-color: transparent;
        transition: background-color 0.15s ease-in-out;
      }

      &:hover {
        &:after {
          background-color: ${theme.color.white};
        }
      }
    }
  }

  @media ${theme.breakpoints.md} {
    position: relative;

  }
`

const MenuToggle = styled('button')`
  position: absolute;
  right: 0;
  bottom: 0;
  appearance: none;
  border: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 100;
  font-size: ${theme.typography.body};
  color: ${theme.color.white};
  background: none;
  z-index: 10;
  pointer-events: auto;
  outline: none;
  cursor: pointer;

  @media ${theme.breakpoints.md} {
    display: none;
  }
`


const NavComponent = ({ siteTitle, location }) => {
  const [{ globals }, dispatch] = useStateValue();  

  return (
    <Nav>
      <Inner>
        <SiteTitle>
          <Link to="/">
            {siteTitle}
          </Link>
        </SiteTitle>
        { !globals.hideMenu &&
          <Menu />
        }

        { globals.showCloseButton &&
          <CloseButton location={ location } />
        }

        { !globals.hideMenu &&
          <MenuToggle
            onClick={() => dispatch({
              type: 'changeGlobals',
              newGlobals: { showMobileMenu: !globals.showMobileMenu }
            })}
          >
            Menu
          </MenuToggle>
        }
      </Inner>
    </Nav>
  )
}



NavComponent.propTypes = {
  siteTitle: PropTypes.string,
}

NavComponent.defaultProps = {
  siteTitle: ``,
}


export default NavComponent
