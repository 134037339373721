import React from 'react'
import Div100vh from 'react-div-100vh'
import { StateProvider } from './src/state'
import Reducer from './src/reducer'


export const wrapRootElement = ({ element }) => {  
  return (
    <StateProvider reducer={Reducer}>
      <Div100vh>{element}</Div100vh>
    </StateProvider>
  );
}