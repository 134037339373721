exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-grid-js": () => import("./../../../src/templates/Grid.js" /* webpackChunkName: "component---src-templates-grid-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-one-column-js": () => import("./../../../src/templates/OneColumn.js" /* webpackChunkName: "component---src-templates-one-column-js" */),
  "component---src-templates-three-column-js": () => import("./../../../src/templates/ThreeColumn.js" /* webpackChunkName: "component---src-templates-three-column-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/Work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

