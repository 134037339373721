import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import normalize from 'normalize.css'


// import { ContextProviderComponent } from "../context"
import { useStateValue } from '../state';

// Theme variables
import theme from '../theme'

// Components
import Header from './Header'
import Transition from './Transition'


// import Background from './_Background'
import RandomBackground from './RandomBackground'


const Content = styled.div`
  position: relative;
  //min-height: 100vh;
  height: 100%;
  z-index: 1;
  overflow: hidden;
`

const Main = styled('main')`
  height: 100%;
  //overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
   -webkit-overflow-scrolling: touch;
`


const Layout = ({ children, hideMenu, hideBackground, showCloseButton, location, showMobileMenu }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [{ globals }, dispatch] = useStateValue();

  return (
    <div style={{ 'height': '100%', 'width': '100%' }}>
      <Global
        styles={css`
          ${normalize}

          #gatsby-focus-wrapper, 
          #___gatsby {
            height: 100%;
          }

          body {
            background-color: ${theme.color.black};
            color: ${theme.color.white};
            font-family: 'Favorit', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
            font-size: ${theme.typography.body};
            line-height: ${theme.typography.lineHeight};
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            overflow: hidden;

            @media ${theme.breakpoints.md} {
              overflow: auto;
            }
          }
        `}
      />
      <Content>
        <Header 
          siteTitle={data.site.siteMetadata.title} 
          hideMenu={globals.hideMenu} 
          showCloseButton={globals.showCloseButton}
          location={location}
        />
        <Transition location = { location }>
          <Main>{children}</Main>
        </Transition>
      </Content>

      <RandomBackground hideBackground={ globals.hideBackground } />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout