import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { Link } from './LinkWithPrevURL'
import styled from '@emotion/styled'
// import GaussianBlur from 'react-gaussian-blur'


import theme from '../theme';
import { useStateValue } from '../state'


const StyledMenu = styled.ul`
  /*width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
  margin: 0;*/
  display: none;

  @media ${theme.breakpoints.md} {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    margin-left: auto;
    z-index: 10;
  }
`

const StyledMenuDrawerMobile = styled('div')`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 178px;
  background-color: ${theme.color.black};
  transition: transform 0.25s ease-in-out;

  transform: ${props =>
    props.showMobileMenu ? 'translateY(0)' : 'translateY(100%)'};

  @media ${theme.breakpoints.md} {
    display: none;
    pointer-events: none;
  }
`

const StyledMenuDrawerInnerMobile = styled('div')`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 90px;
    transform: translateY(-99%);
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 58%);

    background: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.011) 8%,
      hsla(0, 0%, 0%, 0.044) 15.4%,
      hsla(0, 0%, 0%, 0.094) 22.3%,
      hsla(0, 0%, 0%, 0.16) 28.7%,
      hsla(0, 0%, 0%, 0.237) 34.8%,
      hsla(0, 0%, 0%, 0.323) 40.7%,
      hsla(0, 0%, 0%, 0.415) 46.4%,
      hsla(0, 0%, 0%, 0.51) 52.1%,
      hsla(0, 0%, 0%, 0.605) 57.9%,
      hsla(0, 0%, 0%, 0.696) 63.9%,
      hsla(0, 0%, 0%, 0.782) 70.1%,
      hsla(0, 0%, 0%, 0.858) 76.7%,
      hsla(0, 0%, 0%, 0.921) 83.9%,
      hsla(0, 0%, 0%, 0.97) 91.6%,
      hsl(0, 0%, 0%) 100%
    );

  }
`

const StyledMenuMobile = styled('ul')`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 178px;
  margin: 0;
  padding: 0;
  text-align: center;

  --delay: 0.07s;

  pointer-events: ${props =>
    props.showMobileMenu ? 'auto' : 'none'};

  li {
    transition: opacity 0.2s;
    opacity: ${props =>
      props.showMobileMenu ? '1' : '0'};
  }

  a {
    pointer-events: ${props =>
      props.showMobileMenu ? 'auto' : 'none'};
  }

  li:nth-of-type(5) {
    transition-delay: calc(var(--delay) * 2.2);
  }

  li:nth-of-type(4) {
    transition-delay: calc(var(--delay) * 1.65);
  }

  li:nth-of-type(3) {
    transition-delay: calc(var(--delay) * 1.1);
  }

  li:nth-of-type(2) {
    transition-delay: calc(var(--delay) * 0.55);
  }

  li:nth-of-type(1) {
    transition-delay: calc(var(--delay) * 0);
  }

  &.active {
    li:nth-of-type(5) {
      transition-delay: calc(var(--delay) * 0);
    }

    li:nth-of-type(4) {
      transition-delay: calc(var(--delay) * 1);
    }

    li:nth-of-type(3) {
      transition-delay: calc(var(--delay) * 2);
    }

    li:nth-of-type(2) {
      transition-delay: calc(var(--delay) * 3);
    }

    li:nth-of-type(1) {
      transition-delay: calc(var(--delay) * 4);
    }
  }

  @media ${theme.breakpoints.md} {
    display: none;
    pointer-events: none;
  }
`

const StyledMenuItem = styled.li`
  list-style: none;
  margin-left: 5px;
  margin-bottom: 10px;
  opacity: 0;
  pointer-events: none;

  a {
    padding: 5px;
  }

  @media ${theme.breakpoints.md} {
    margin-bottom: 0;
    opacity: 1;
    pointer-events: auto;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 5px;
    bottom: 3px;
    left: 5px;
    height: 1px;
    background-color: transparent;
    transition: background-color 0.15s ease-in-out;
  }

  &.active {
    &:after {
      background-color: ${theme.color.white};
    }
  }

  @media ${theme.breakpoints.md} {
    &:hover {
      &:after {
        background-color: ${theme.color.white};
      }
    }
  }
`;


const Menu = () => {
  const [{ globals }, dispatch] = useStateValue();  

  const data = useStaticQuery(graphql`
    query {
      wpMenu(locations: { eq: NAVIGATION_MENU }) {
        id
        name
        menuItems {
          nodes {
            id
            label
            title
            path
          }
        }
      }
    }
  `)

  return (
    <>
      <StyledMenu>
        {data.wpMenu.menuItems.nodes.map((node) => {
          const frontEndURI = process.env.GATSBY_FRONTEND_URI;
          const relativeURL = node.path.replace(frontEndURI, '');

          return (
            <StyledMenuItem key={ node.id } >
              <StyledLink 
                to={ relativeURL }
                activeClassName="active"
                partiallyActive={true}
              >
                { node.label }
              </StyledLink>
            </StyledMenuItem>
          )})}
      </StyledMenu>

      <StyledMenuDrawerMobile showMobileMenu={ globals.showMobileMenu } >
        <StyledMenuDrawerInnerMobile />
      </StyledMenuDrawerMobile>

      <StyledMenuMobile 
        showMobileMenu={ globals.showMobileMenu }
        className={globals.showMobileMenu ? 'active': null} 
      >
        {data.wpMenu.menuItems.nodes.map((node) => {
          const frontEndURI = process.env.GATSBY_FRONTEND_URI;
          const relativeURL = node.path.replace(frontEndURI, '');

          //console.log(frontEndURI)
          //console.log(relativeURL)

          return (
            <StyledMenuItem key={ node.id }>
              <StyledLink 
                to={ relativeURL }
                activeClassName="active"
                partiallyActive={true}
              >
                { node.label }
              </StyledLink>
            </StyledMenuItem>
          )})}
      </StyledMenuMobile>
    </>
  )
  
}

export default Menu