const theme = {
  color: {
    white: '#ffffff',
    black: '#000000',
    red: '#ff0000',
    blue: '#0085ff'
  },
  typography: {
    heading: '18px',
    title: '14px',
    body: '14px',
    subheading: '10px',
    lineHeight: '1.2',
    desktop: {
      heading: '30px',
      title: '18px',
    }
  },
  breakpoints: {
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
  }
};

export default theme