export default function Reducer(state, action) {
  //console.log(state);
  //console.log(action);

  switch (action.type) {
    case 'changeGlobals':
      return {
        ...state,
        globals: action.newGlobals
      };
    case 'updateDownloadLink':
      return {
        ...state,
        downloadLink: action.link
      }
    default:
      return state;
  }
};