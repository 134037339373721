import React, { useEffect } from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { Link } from './LinkWithPrevURL'
import styled from '@emotion/styled'
import screenfull from 'screenfull'

import theme from '../theme';
import { useStateValue } from '../state'


const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-weight: 500;
  font-size: ${theme.typography.subheading};
  z-index: 10;
`

const ListItem = styled.li`
  list-style: none;
  margin-left: 5px;
  display: flex;

  a,
  span {
    padding: 7px 7px 0 7px;
  }
`

const StyledDownload = styled('span')`
  display: none;
  cursor: pointer;
  pointer-events: auto;

  @media ${theme.breakpoints.md} { 
    display: inline-block
  }
`

const StyledFullScreen = styled('span')`
  display: none;
  cursor: pointer;
  pointer-events: auto;


  span {
    display: none;
    padding: 0;

    .isFullscreen & {
      display: inline-block;
    }
  }

  @media ${theme.breakpoints.md} { 
    display: inline-block
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  position: relative;

  .isFullscreen & {
    display: none;
  }

  /*&:after {
    content: '';
    z-index: -1;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    left: -20px;
    border-top-left-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    filter: blur(20px);
    opacity: 0.9;
    transition: background-color 0.35s ease-in-out;
  }

  &.active {
    &:after {
      background-color: ${theme.color.red};
    }
  }

  &:hover {
    &:after {
      background-color: ${theme.color.blue};
    }
  }*/
`;


const CloseButton = ({ location }) => {
  const [{ globals, downloadLink }, dispatch] = useStateValue();  

  
  useEffect(() => {
    if(screenfull.isEnabled) {
      screenfull.on('change', toggleFullScreenClass)
    }
    
    return () => {
      if(screenfull.isEnabled) {
        screenfull.off('change', toggleFullScreenClass)
      }
    }
  }, []);
  

  const toggleFullScreenClass = () => {
    
    if(screenfull.isFullscreen) {
      document.body.classList.add('isFullscreen');
    } else {
      document.body.classList.remove('isFullscreen');
    }
    
  }

  let relativeURL
  if(location.state && typeof location.state.prevUrl != 'undefined') {
    relativeURL = location.state.prevUrl.replace(location.origin, '');
  } else {
    relativeURL = '/';
  }

  return (
    <List>
      <ListItem>
        { downloadLink &&
          <StyledDownload>
            <a href={ downloadLink } download>Download</a>
          </StyledDownload>
        }
        <StyledFullScreen
          onClick={() => {
            screenfull.toggle(); 
          }}
        >
          <span>Exit</span> Full Screen
        </StyledFullScreen>
      </ListItem>
      <ListItem>
        <StyledLink 
          to={relativeURL}
        >
          Close
        </StyledLink>
      </ListItem>
    </List>
  )
}

export default CloseButton