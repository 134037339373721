import React, {useState} from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'


const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; 
  transition: opacity 0.25s ease-in-out;
  opacity: ${props =>
    props.hideBackground ? `0` : `1`};
`

const BackgroundFigure = styled.figure`
  position: relative;
  margin: 0;
`

const BackgroundImg = styled(GatsbyImage)`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  opacity: 0.875;
`


const randomGenerator = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}


const RandomBackground = ({ hideBackground }) => {
  const data = useStaticQuery(graphql`query ImageQuery {
  wpPage(slug: {eq: "home"}) {
    homeFields {
      backgroundImage {
        images {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`)


  //console.log(data)
  //return null


  const images = data.wpPage.homeFields.backgroundImage.images
  // console.log(images)
  // const randomPosition = randomGenerator(0, images.length - 1)
  const randomPositionInitial = randomGenerator(0, images.length - 1);
  // console.log(randomPositionInitial)

  const [randomizedImage, setRandomizedImage] = React.useState(null)
  // const [randomPosition, setRandomPosition] = React.useState(null)
  // const randomizedImage = images[randomPositionInitial].localFile.childImageSharp

  React.useEffect(() => {
    // setRandomPosition(randomPositionInitial)
    setRandomizedImage(images[randomPositionInitial].localFile.childImageSharp)
  }, [])

  // console.log(randomizedImage)
  const image = getImage(randomizedImage)

  return (
    <BackgroundContainer hideBackground={ hideBackground }>
      <BackgroundFigure>
        { image && 
          <BackgroundImg 
            image={ image } 
            // durationFadeIn={ 1000 }
            alt=''
          />
        }
      </BackgroundFigure>
    </BackgroundContainer>
  )
}

export default RandomBackground